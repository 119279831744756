export const translationsEn = {
    imagesTab: {
        imagesTabName: "Images",
        downloadText: "Download Vehicle Images",
        vin: "You can enter up to 5 VINs separated by comma",
        imagesSettings: "Images Settings",
        imagesSettingsDesc: "You can change the following images parameter to get the vehicle images best fit to your needs.",
        editImage: "Edit Vehicle Images",
        editDesc: "You can edit the exterior images optionally with a background image, with up to two logo images and a bulk image.",
        crop: {
            both: "both",
            top: "top",
            bottom: "bottom"
        },
        position: {
            standard: "Standard",
            centerStage: "Center Stage",
            center: "Center",
            frontWheel: "Front Wheel",
            middle: "Middle"
        },
        layouts: {
            ext: "Exterior",
            int: "Interior",
            bis: "360° Interior",
        },
        infoTable: {
            lightDesc: "You can select day view images (Light = Day, default value) or night view images (Light = Night)",
            roofDesc: "Parameter only applies for cabrio models. You can select roof open images (Roof = Open) or roof closed images (Roof = Closed, default value)",
            backgroundDesc: "You can select transparent images (Background = Without) or images with one of the available backgrounds (Background = With). The selection list of available backgrounds is shown, if this parameter is set to “With”.",
            backgroundSelectDesc: "You can select one of the listed backgrounds. The default value is pre-selected and depends on the vehicle model.",
            fileFormatDesc: "You can select one of the formats: PNG (default value), JPEG or WEBP. The JPEG format doesn’t work for transparent images and you would receive white or black backgrounds for this images request. WEBP is the recommendation as it is supported by most modern browsers and the images have the smallest file size.",
            imageFormatDesc: "You can select 16:9 format (default value) or the 4:3 format. All images with 4:3 format will be cropped to this image resolution and will have a white-space border. You can define the white-space border position with the crop Postion parameter.",
            cropPositionDesc: "You can define the white-space border position as both (top and botton), top (only) or bottom (only).",
            vehiclePositionDesc: "This value uniforms the vehicle position and might be used to harmonize the visual placing of the cars. This feature could be used e.g. in the stock car search result list as the “hero” image or in the model overview list. You can set the value to one of the possible values: Standard, Center Stage, Center, Front Wheel or Middle. As per default (standard) the image positions are not uniformed.",
            fallbackDesc: "With fallback=true we will provide fallback images in case, no images are available for that model and parameter combination. We will use appropriate default parameters instead, e.g. “roof Open”=false instead of selected “roof Open”=true."
        },
        imageCompose: {
            preview: "Composition Preview",
            selectVIN: "Select VIN",
            logoLeft: "Logo left position",
            logoRight: "Logo right position",
            logoTop: "Indent top in px",
            logoResize: "Logo resize in %",
            tooltipOnPosition: "Valid intend range is 0 .. 100.",
            tooltipOnResize: "Valid resize range is 25 .. 400 percent",
            logoUpperLeft: "Logo Upper Left",
            logoUpperRight: "Logo Upper Right",
            bannerImage: "Banner Image",
            backgroundImage: "Background Image",
            editVehicleImages: "Edit Vehicle Images",
            editVehicleImagesDataTable: {
                dataTableDesc: "You can edit the exterior images optionally with a background image, with up to two logo images and a banner image.",
                backgroundImageDesc: "Upload your own background image. We recommend a HD resolution either from 1920x1080px up to 4512x3384 for 16:9 images or a HD resolution of 1920x1440 for 4:3 images.",
                logoUpperLeftDesc: "Upload a (logo) image that will be placed in the upper left corner. We recommend a HD resolution that fits to the background image.",
                logoUpperRightDesc: "Upload a (logo) image that will be placed in the upper right corner. We recommend a HD resolution that fits to the background image.",
                bannerImageDesc: "Upload a image that will be placed in bottom. We recommend a HD resolution that fits to the background image."
            },
            imageSizeRangeError: "<Right/left/top> position of the <first/second> logo cannot be negative, non-integer or higher than 100px. It is set to 20px.",
            rangeErrorTitle: "Exceeded Range",
            notification: {
                topFirst: "Top position of the first logo cannot be negative, non-integer or higher than 100px. It is set to 20px.",
                topSecond: "Top position of the second logo cannot be negative, non-integer or higher than 100px. It is set to 20px.",
                leftFirst: "Left position of the first logo cannot be negative, non-integer or higher than 100px. It is set to 20px.", // Zaten yukarıda toplu halde varmış
                rightSecond: "Right position of the second logo cannot be negative, non-integer or higher than 100px. It is set to 20px.",
                resizeImage: "Resize image cannot be negative, non-integer, less than 25% or higher than 400%. It is set to 100 percent."
            },
            scaleToFit: "Scale To Fit"
        },
        backgrounds: {
            T0: "White",
            T1: "Grey (Legacy)",
            T5: "Holodeck (Legacy)",
            T6: "SSP (Legacy)",
            T7: "SSP-AMG (Legacy)",
            T8: "Showroom",
            T9: "Transparent (PNG - P27) / black (JPG - A27)",
            T10: "Outdoor",
            T11: "Museum",
            T12: "Loft",
            T13: "Hall Graz (G-Class only)",
            T14: "EQ",
            T15: "Salt Lake (US only)",
            T40: "T40 (Vans only)",
            T41: "T41 (Vans only)",
            T42: "T42 (Vans only)",
            T99: "Transparent without shadow (PNG - P27) / black (JPG - A27)"
        },
        default: "Default",
        image360View: {
            title: "360° Images View",
            description: "You can view the 360° images of the selected vehicle from exterior and interior perspective. Move the 360° view with the mouse.",
            headingExterior: "360° Exterior View",
            headingInterior: "360° Interior View",
        },
        reloadToClick:'Click to reload'
    },
    equipmentsTab: {
        equipmentsTabName: "Equipments",
        downloadText: "Download Vehicle Equipment Images",
        engineComponent: "Engine",
        trimComponent: "Trim",
        rimComponent: "Rim",
        upholsteryComponent: "Upholstery",
        paintComponent: "Paint",
        upperPaintComponent: "Paint2 (Upper Paint)",
        equipmentComponent: "Equipment",
        filterEquipments: "Filter Equipments",
        filterEquipmentsIntroText: `You can filter specific option codes and image types. If you want to get all available option codes or image types, leave the fields empty. To filter for specific option codes, please enter the codes separated by comma (e.g.: 233, A51, P69). To filter for specific image types, please enter the values separated by comma (e.g.: A23, A24).`,
        showImageTypesHoverInfoBox: "Show first image per equipment category",
        disabledFilterText: `Image Types filter is disabled. To enable it, change the "Show Image Types" switch to the all`,
        applyFiltersHoverInfoBox: "The equipment images are provided in several image resolutions. This is defined by the image type and covers the values listed in the table below.",
        equipmentFilter: {
            resolution: "Resolution",
            aspectRatio: "Aspect Ratio",
            size: "Size",
            small: "Small",
            medium: "Medium",
            large: "Large",
            square: "Square",
        }
    },
    videosTab: {
        videosTabName: "Videos",
        downloadText: "Download Vehicle Videos",
        editVideoScenesIntroText: `In this panel, you can change the default values of the scenes and specify your dealer specific content. Please reload the updated video with the LOAD VIDEO button. If you change the entered VIN and press the LOAD VIDEO button, then video data for the new VIN will be loaded and all of your previous changes will be overwritten.`,
        editVideoScenesClosureText: `You can save your settings in a local file and reload them later at any time. You can also restore the default values provided by the vehicle videos API request.`,
        editSceneTablesPopover: {
            videoAdjustText: "You can change the video player behavior with these settings, if the users' web browser supports these features:",
            videoContentText: `You can change the vehicle related content fo the video with these settings:`,
            videoSceneText: "You can change the video scenes used for the dedicated video. Thus, you are able to enable or disable the scenes and also change the some elements.",
            videoAdjustDataTable: {
                autoPlay: `Toggle to define if the video will start manually after user interaction ("off") or if the video will start directly after loading ("on").`,
                autoReplay: `Toggle to define if the video loops (auto replay) after completion. If Auto Replay = "off", the video will play just once. If Auto Replay = "on", the video will play in a loop.`
            },
            videoContentDataTable: {
                touchDevice: `Defines the user-action in the end scene. Shows either a button (Touch Device = "on") or the QR code (Touch Device = "off"). Please consider that the MP4 video format doesn’t support interactive buttons. Thus, the QR image in the end scene is recommended (Touch Device = “off”).`,
                muteAudio: "Disables the audio of the video (muted). Default: false: video will play with audio (unmuted).",
                light: "You could select the light option. The default setting is day light scenes. You could create a video with night scene images. Please consider that night images aren’t available for all models.",
                outputFormat: "Defines the video output format optimized for standard screens (16:9) in landscape format or mobile devices in portrait format.",
                selectMarket: "Defines market and language locale for the text elements and vehicle descriptions.",
                equipmentCodes: "This is a mandatory field if equipments should be shown in the video. It defines the equipments to be shown in the video with equipment codes separated by comma - either 2 equipment codes or 3 equipment codes.",
            },
            videoScenesDataTable: {
                startScene: "Video scene with exterior and interior image details of the vehicle. You change the text elements only.",
                specScene: "Video scene with up to 4 elements to show vehicle specification details or highlights. You can change all text elements.",
                twoEquipmentsScene: "Video scene with up to 2 elements to show vehicle option code details with the related image and description. You could use either the scene with 2 option codes or with 3 option codes.",
                threeEquipmentsScene: "Video scene with up to 3 elements to show vehicle option code details with the related image and description. You could use either the scene with 2 option codes or with 3 option codes.",
                outroScene: "Video closing scene with outro animation of the vehicle. You can change the text element.",
                endScene: "Video end scene with several elements and call-to-action button or qr code. You can change the elements in the section below.",
            },
            videoOutputSettingsDataTable: {
                videoLan: "en",
                description: "You can change video output format to MP4 or SDK.",
                outputFormat: "Video output format can be MP4 or SDK Data",
                outputFormatDefault: "SDK Data",
                outputFormatDefault2: "MP4",
                videoResolution: "Available for MP4 output format. The resolution (low, medium, high) impacts the video quality and the resulting MP4 file size.",
            },
            videoAdjustPanel: {
                videoAdjustText: "In this panel, you can change the default values of the scenes and specify your dealer specific content. Please reload the updated video with the LOAD VIDEO button. If you change the entered VIN and press the LOAD VIDEO button, then video data for the new VIN will be loaded and all of your previous changes will be overwritten.",
                videoAdjustFileInputText: "You can save your settings in a local file and reload them later at any time. You can also restore the default values provided by the vehicle videos API request.",
                videoEditText: "You can change the end scene elements to best fit your dealer needs.",
                videoEditTextStartScene: "You can change the start scene elements to best fit your dealer needs.",
                videoEditTextSpecScene: "You can change the spec scene elements to best fit your dealer needs.",
                videoEditTextOutroScene: "You can change the outro scene elements to best fit your dealer needs.",
                editVideoEndSceneDataTable: {
                    dealerLogo: "Image url for your dealer logo in png or jpeg format.",
                    backgroundImage: "Image url for your dealer specific background image.",
                    textMessage: "Description of the end scene.",
                    buttonLabel: 'Description of call-to-action button. Button is shown if parameter "Touch Device" = "on".',
                    buttonLink: "Target link of the call-to-action button.",
                    qrCode: `Image url for your daler specific QR code. QR Code is shown if parameter "Touch Device" = "off".`,
                    vehiclePerspective: `Sets the vehicle perspective from 0° .. 350° (default 40°)".`
                },
                editVideoStartSceneDataTable: {
                    scene1text: "First message of the start scene.",
                    scene2text: "Second message of the start scene.",
                    scene3text: "Third message of the start scene.",
                },
                editVideoSpecSceneDataTable: {
                    title1: "Title for first specification message.",
                    text1: "Description for first specification message.",
                    title2: "Title for second specification message.",
                    text2: "Description for second specification message.",
                    title3: "Title for third specification message.",
                    text3: "Description for third specification message.",
                    title4: "Title for fourth specification message.",
                    text4: "Description for fourth specification message.",
                    textColourBlack: "Set the text colour to white (off, default value) or black (on)"
                },
                editVideo2EquipmentScene: {
                    title: "Title for the equipments scene.",
                    image1: "Image URL for first equipment to be shown in png or jpeg format.",
                    text1: "Description of first option code.",
                    image2: "Image URL for second equipment to be shown in png or jpeg format.",
                    text2: "Description of second option code.",
                },
                editVideo3EquipmentScene: {
                    title: "Title for the equipments scene.",
                    image1: "Image URL for first equipment to be shown in png or jpeg format.",
                    text1: "Description of first option code.",
                    image2: "Image URL for second equipment to be shown in png or jpeg format.",
                    text2: "Description of second option code.",
                    image3: "Image URL for third equipment to be shown in png or jpeg format.",
                    text3: "Description of third option code.",
                },
                editVideoOutroSceneDataTable: {
                    outroText: "Message for the outro scene."
                }
            }
        },
        editVideoSceneHeadings: {
            editVideoStartSceneTitle: "Edit Video Start Scene",
            editVideoSpecSceneTitle: "Edit Video Spec Scene",
            editVideoEndSceneTitle: "Edit Video End Scene",
            editVideoOutroSceneTitle: "Edit Video Outro Scene",
            editAllScenes: "Edit Video Scenes"
        },
        editVideoSceneLabels: {
            sceneLabel: "Scene {{number}} Text",
            titleLabel: "Title {{number}}",
            textLabel: "Text {{number}}",
            imageLabel: "Image {{number}}",
            videoEditBlackColor: "Text Color Black",
            outroText: "Outro Text",
        },
        endSceneEntries: {
            dealerLogo: "Dealer Logo",
            buttonLabel: "Button Label",
            backgroundImage: "Background Image",
            buttonLink: "Button Link",
            textMessage: "Text Message",
            qrCode: "QR Code",
            vehiclePerspective: "Vehicle Perspective",
        },
        videoOutputFormatSection: {
            outputFormat: "Output Format",
            videoResolution: "Video Resolution",
            videoResolutionSettings: {
                videoResolutionVeryLow: "Very Low",
                videoResolutionLow: "Low",
                videoResolutionMedium: "Medium",
                videoResolutionHigh: "High",
                videoResolutionVeryHigh: "Very High"
            }
        },
        videoConfirmationSection: {
            confirmationOnContinueMessage: "Do you want to continue?",
            confirmationOnContinueContent: "It takes up to 5 minutes to create the mp4 video.",
            confirmationOnContinueOkText: "OK",
            confirmationOnContinueCancelText: "CANCEL",
            confirmationOnReloadData: "We need to reload the data for the vehicle {{vin}}",
            confirmationOnSaveConfiguration: "We need to reload the data for the vehicle {{vin}}. Would you like to save your changes?",
            confirmationYesButton: "Yes",
            confirmationNoButton: "No",
            confirmationCheckDialog: "New VIN-based video data has been loaded and merged with your own values. Please check the resulting video."
        },
        videoProcessingSection: {
            processingFirstMessage: "Step 1 of 2: Preparing MP4 video...",
            processingSecondMessage: "Step 2 of 2: Video created. Preparing download...",
        },
    },
    specificationsTab: {
        specificationsTabName: "Specifications",
        modelDataHeading: "Model data",
        emissionsTabName: "Emissions",
        equipmentsTabName: "Equipments",
        meServicesTabName: "Digital Extras",
        simSearchTabName: "Similarity Stock search",
        rycTabName: "Fresh configuration",
        specificationsContentSettings: "Specification Settings",
        specificationsContentSettingsDesc: "You can change the following specification parameter to get the information best fit to your needs.",
        specificationsParameters: "Specification Parameters",
        specificationsParametersDesc: "You can change the additional specifications parameter to get the information best fit to your needs.",
        specificationsParametersLabels: {
            additionalSpecs: "Additional Specs",
            optionsNullDescription: "Options Null Description",
            options: "Options",
            technicalData: "Technical Data",
            payloadNullValues: "Payload Null Values"
        },
        downloadText: "Download Vehicle Specification Data",
        filterSpecs: "Filter Specifications",
        specificationsAccordionName: "Specifications",
        copyTheData: "Copy",
        copiedData: "Copied",
        infoTable: {
            contentSettings: {
                specificationsDesc: "Toggle to enable or disable Specifications.",
                emissionsDesc: "Toggle to enable or disable Emissions.",
                equipmentsDesc: "Toggle to enable or disable Equipments.",
                meServicesDesc: "Toggle to enable or disable Digital Extras.",
                simSearchDesc: "Toggle to enable or disable Similarity Stock search.",
                rycDesc: "Toggle to enable or disable lockup for a fresh and available configuration from the current model year.",
                selectMarket: "Defines market and language locale for the text elements and vehicle descriptions.",
            },
            specificationsParameters: {
                additionalSpecsDesc: "Changes response payload and omits additionalSpecs{} object with additional attributes.",
                optionsNullDescriptionDesc: "Changes response payload and omits option codes with null descriptions.",
                optionsDesc: "Changes response payload and omits options{} object with all option codes.",
                technicalDataDesc: "Changes response payload and omits technicalData{} object with technical data attributes.",
                payloadNullValuesDesc: "Changes response payload and omits attributes with null values."
            }
        }
    },
    filter: {
        searchEntry: "Search Entry",
        hideNull: "Hide Null Entries"
    },
    modelsTab: {
        modelsTabName: "Models",
        modelDataHeading: "Model data",
        downloadText: "Download Vehicle Model Information",
        modelsIntroText: "Download detailed model data for a specific vehicle model or download free images for all available vehicle models.",
        baumuster: "Baumuster*",
        modelYear: "Model Year*",
        changeYear: "Change Year",
        nationalSalesType: "National sales type",
        technicalData: "Technical data",
        modelsContentSettings: "Model Settings",
        modelsContentSettingsDesc: "You can change the following model details parameters to get the information best fit to your needs.",
        infoTable: {
            selectMarket: "Defines market and language locale for the text elements and vehicle descriptions.",
            baumuster: "Baumuster for the Model.",
            modelYear: "Model year for the Model.",
            changeYear: "Change year for the Model.",
            nationalSalesType: "National Sales Type (NST) for the Model.",
            technicalData: "Toggle to enable or disable Technical Data.",
        },
        filterModels: "Filter Models",
    },
    loadSaveButtons: {
        loadSampleImages: "LOAD SAMPLE IMAGES",
        loadImages: "LOAD IMAGES",
        loadImagesTabVideo: "LOAD IMAGES TAB VIDEO",
        loadSampleVideo: "LOAD SAMPLE VIDEO",
        loadVideo: "LOAD VIDEO",
        saveVideo: "SAVE VIDEO",
        loadSampleSpecs: "LOAD SAMPLE SPECS",
        loadSpecs: "LOAD SPECS",
        copySpecs: "COPY SPECS",
        saveSpecs: "SAVE SPECS",
        saveImages: "SAVE IMAGES",
        saveImage: "SAVE IMAGE",
        showImages: "Show Images",
        saveComposedImage: "SAVE COMPOSED IMAGE",
        saveSettings: "SAVE SETTINGS",
        loadSettings: "LOAD SETTINGS",
        resetSettings: "RESET SETTINGS",
        loadBackgrounds: "LOAD BACKGROUNDS",
        loadSampleData: "LOAD SAMPLE DATA",
        loadData: "LOAD DATA",
        saveData: "SAVE DATA"
    },
    components: {
        // Images Tab
        light: "Light",
        dayLight: "Day",
        nightLight: "Night",
        roof: "Roof",
        roofClosed: "Closed",
        roofOpen: "Open",
        background: "Background",
        backgroundScene: "Background Scene",
        backgroundSelection: "Background Selection",
        withoutBackground: "Without",
        withBackground: "With",
        fileFormat: "File Format",
        imageFormat: "Image Format",
        cropPosition: "Crop Position",
        cropPosition43: "Crop Position",
        vehiclePosition: "Vehicle Position",
        fallback: "Fallback",
        fallbackOn: "true",
        fallbackOff: "false",

        // Equipments Tab
        applyFiltersButton: "Apply Filters",
        showImageTypes: "Show Image Types",
        single: "Single",
        all: "All",
        showAllButton: "Show All",

        // Videos Tab
        videoPlayerSettings: "Video Player Settings",
        autoPlay: "Auto Play",
        autoReplay: "Auto Replay",
        videoContentSettings: "Video Content Settings",
        touchDevice: "Touch Device",
        muteAudio: "Mute Audio",
        equipmentCodesPlaceholder: "Equipment Codes",
        videoSceneSettings: "Video Scene Settings",
        startScene: "Start Scene",
        specScene: "Spec Scene",
        outroScene: "Outro Scene",
        endScene: "End Scene",
        twoEquipmentsScene: "2-Equipments Scene",
        threeEquipmentsScene: "3-Equipments Scene",
        videoOutputSettings: "Video Output Settings",
        copyClipboard: "COPY TO CLIPBOARD",
        outputFormat: "Output Landscape",
        outputLandscape: "On",
        outputPortrait: "Off",
        blingsProject: "Blings project",

        // Specifications Tab
        hideNullEntries: "Hide Null Entries",

        // Common
        onButton: "On",
        offButton: "Off",
        selectMarket: "Select Market",
        showAll: "Show All",
        collapseAll: "Collapse All", //warninge bak
    },
    infoTexts: {
        imagesFound: "images found",
        imageFound: "image found",
        optionCodesFound: "option codes found",
        optionCodeFound: "option code found",
    },
    placeholders: {
        apiKeyPlaceholder: "API Key",
        vinNumberPlaceholder: "VIN",
        vinNumbersPlaceholder: "VIN(s)",
        equipmentCodesPlaceholder: "Equipment Codes",
        imageTypesPlaceholder: "Image Types",
        imageTypePlaceholder: "Image Type",
        searchEntryPlaceholder: "SearchEntry",
        description: "Description",
        parameter: "Parameter"
    },
    tabs: {
        images: "Images",
        equipments: "Equipments",
        videos: "Videos",
        specifications: "Specifications",
        models: "Models"
    },
    notification: {
        error: "Error",
        warning: "Warning",
        finished: "Finished",
        info: "Info",
        information: "Information",
        loading: "Loading...",
        gettingImages: "Images loaded for: ",
        gettingEquipments: "Getting equipments for: ",
        gettingEquipmentSample: "Getting equipments for: sample",
        failedImage: "Failed to load image for: ",
        loadedImages: "Images loaded for: ",
        partiallyLoad: "New VIN-based video data has been loaded and merged with your own values. Please check the resulting video.",
        fullyLoad: "User content values loaded.",
        nothingToSave: "Nothing to save. No entries changed.",
        notMatchingFilters: "Filter queries do not match with equipments.",
        specificationFilter: "String {{string_name}} not found with null filter:",
        specificationError: "Failed to get specifications for: ",
        vehiclesError: "No specification data found for this VIN: ",
        emissionError: "No emission data found for this VIN: ",
        equipmentError: "No equipment data found for this VIN: ",
        specificationLoaded: "Specification data loaded for: ",
        equipmentCodeCount: "The Equipment Codes count should be two or three.",
        videoCompleted: "The video can be edited for: {{vin}} and market: {{marketID}}",
        videoEmptyScene: "All Scenes are deleted. Video can not be shown.",
        videoNonSufficentImage: "Insufficent number of images.",
        invalidVIN: "Invalid VIN",
        invalidVINLongDesc: "VIN must be 17 characters long",
        invalidVINDesc: "You have entered invalid VINs. Please correct.",
        invalidVINCount: "You have entered too much VINs. Please correct.",
        labelBackground: "Background",
        labelImageF: "Image Format",
        switched: "Switched to:",
        backgroundWithout1: "Without. Parameter Vehicle Position available.",
        backgroundWith1: "With. Parameter Vehicle Position not available.",
        backgroundWithout2: "Without. Parameter Background scene not available.",
        backgroundWith2: "With. Parameter Background scene available.",
        imageFormat4_3: "4:3. Parameter Crop Position avaliable.",
        imageFormat16_9: "16:9. Parameter Crop Position not available.",
        loadedImageFileSizeError: "Error: Image size must be less than {{fileSize}} MB.",
        notCreated: "The video cannot be generated at this time.",
        invalidBackgroundErrorMessage: "New VIN. Please reload backgrounds first (LOAD BACKGROUNDS).",
        loadedData: "Data loaded for: ",
        failedModelData: "The given vehicle could not be found.",
        sample: "Sample"
    },
    mainScreen: {
        intro: "Get images and model specs for every stock vehicle!",
		text: "Coding is not your business? Just use your Product-Keys here to benefit as easily as possible from virtual copies and specifications based on vehicle identification numbers of any specific Mercedes-Benz or smart car. Conveniently available without already having the vehicle on site.",
        exampleTextBold: "Would you like to see a new way how to present your stock?",
		exampleText: "See in our preview what you can get for your Product-Keys to impress your customers.",
		exampleButton: "Show vehicles",
        producstKeyNeeded: "Product-Keys needed?",
        purchaseThem: " Purchase them in our",
        portal: "portal",
        openingTextPartOne: `Welcome to our Mercedes-Benz Retailer Dashboard (previously Vehicle Images Download Tool): `,
        openingTextPartOneBold: "Get images and model specs for every stock vehicle!",
        openingTextPartTwo: "Benefit from convenient and fast access to marketing data and original Mercedes-Benz and smart images, from the exterior and interior to all available equipment imagery.",
        retailerDashboardVideoText: "Retailer Dashboard: learn all about your benefits.",
        headerBar: {
            mercedesBenzData: "MERCEDES-BENZ DATA",
            products: "PRODUCTS",
            contact: "CONTACT",
            support: "SUPPORT",
            language: "English",
            dataLink: "https://data.mercedes-benz.com/products"
        },
        footerBar: {
            imprint: "Imprint",
            legalNotice: "Legal Notice",
            cookies: "Cookies",
            privacyStatement: "Privacy Statement",
            termsOfUse: "Terms of Use"
        },
        changeLanguage: "Click to change the language to Deutsch"
    },
};