import {Tabs} from 'antd'
import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import Equipment from './Equipment';

import Images from './Images'
import Models from './Models'
import Specification from './Specification';
import Video from './Video'

const tabMap = ["images", "equipments", "videos", "specification", "models"];

function Packages(props) {

  const { tab, handleTabUpdate, apikeyEntered, setApikeyEntered, apikeyRef } = props

  const [width, setWidth] = useState(0)
  const [rowCount, setRowCount] = useState(6);
  const [videoImageList, setVideoImageList] = useState([]);
  const [background, setBackground] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    updateWidth();
    window.addEventListener("resize", updateWidth);

    return () =>
        window.removeEventListener("resize", updateWidth);
  }, [])

  const updateWidth = () => {
    const width = window.innerWidth
    setWidth(width)
    calculateRowCount(width);
  }

  const calculateRowCount = (width) => {
    if (width <= 768 && width > 520) {
      setRowCount(3);
    } else if (width <= 520) {
      setRowCount(2);
    } else if (width <= 1400 && width > 768) {
      setRowCount(4);
    } else {
      setRowCount(6);
    }
  }

  function handleTabChange(key){
    window.history.pushState("null", "Vehicle Images Download Tool",  tabMap[key-1])
    handleTabUpdate(key)
  }

  const items = [
    {
      key: "1",
      label: t("imagesTab.imagesTabName"),
      children: <Images width={width} rowCount={rowCount} setVideoImageList={setVideoImageList}
      background={background} setBackground={setBackground} apikeyEntered={apikeyEntered} setApikeyEntered={setApikeyEntered} apikeyRef={apikeyRef} tabNumber={tab}/>
    },
    {
      key: "2",
      label: t("equipmentsTab.equipmentsTabName"),
      children: <Equipment width={width} rowCount={rowCount} apikeyEntered={apikeyEntered} setApikeyEntered={setApikeyEntered} apikeyRef={apikeyRef} tabNumber={tab}/>
    },
    {
      key: "4",
      label: t("specificationsTab.specificationsTabName"),
      children: <Specification width={width} rowCount={rowCount} apikeyEntered={apikeyEntered} setApikeyEntered={setApikeyEntered} apikeyRef={apikeyRef} tabNumber={tab}/>
    },
    {
      key: "5",
      label: t("modelsTab.modelsTabName"),
      children: <Models width={width} rowCount={rowCount} apikeyEntered={apikeyEntered} setApikeyEntered={setApikeyEntered} apikeyRef={apikeyRef} tabNumber={tab}/>
    }
  ]

  return (
      <div className="Packages">
        <Tabs items={items} defaultActiveKey={tab} activeKey={tab.toString()} type="card" animated={{inkBar: false, tabPane: false}} onChange = {handleTabChange}>
        </Tabs>
      </div>
  )
}

export default Packages;